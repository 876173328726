import { Clippy } from "clippy-react";
import React from 'react';
import { useRef } from "react";
import { render } from "react-dom";


const ClippyR = () => {

window.CLIPPY_CDN = 'https://itsgone.xyz/assets/agents';

const clippy = useRef(null);

const onClippyLoad = () => {
      clippy.current.show();
      clippy.current.speak('welcome to $GONE! Got any cheeze?');
  }
    return (
        <div>
        <Clippy name="Clippy" ref={clippy} onLoad={onClippyLoad}></Clippy>
        </div>            
    
    );
    };
export default ClippyR;