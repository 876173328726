// from Webamp demo

import gone from 'assets/HoDL_Your_GONE.wav';
export const initialTracks = [
  {
    url: "https://xp.itsgone.xyz/static/media/HoDL_Your_GONE.5e13eba3e3ee14be03fa.wav",
    duration: 50,
    metaData: {
      title: 'HoDL UR $GONE',
      artist: '$GONE',
    },
  }
];
